<script>
import {
  SuawForm,
  SuawHeading,
  SuawTextInput,
  SuawInputGroup,
  SuawLabel,
  SuawRadio,
  SuawSelect,
  SuawButton,
  SuawParagraph,
  SuawFileUpload
} from "@/components";
import { ListCountries } from "../../operations.gql";
import { usStates, canadianProvinces } from "../../../../utils/geo/regions.js";
import * as UserApi from "../../api";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
import { convertFileToBase64String } from "@/utils/formatting/files.js";
export default {
  name: "CompleteAccountForm",
  components: { SuawForm, SuawHeading, SuawTextInput, SuawInputGroup, SuawLabel, SuawRadio, SuawSelect, SuawButton, SuawParagraph, SuawFileUpload },
  data() {
    return {
      countries: [],
      loadingCountries: true,
      completeAccountForm: {
        firstName: "",
        lastName: "",
        countryAbbrev: "",
        isLastNameHidden: true,
        city: "",
        region: "",
        postalCode: "",
        avatarUrl: ""
      }
    };
  },
  apollo: {
    countries: {
      query: ListCountries,
      result() {
        this.loadingCountries = false; // Mark loading as complete
      }
    }
  },
  validations() {
    return {
      completeAccountForm: {
        firstName: {
          required,
          maxLength: maxLength(40)
        },
        lastName: {
          required,
          maxLength: maxLength(40)
        },
        countryAbbrev: {
          required
        },
        city: {
          required
        },
        region: {
          required: requiredIf(() => {
            return this.completeAccountForm.countryAbbrev === "US" || this.completeAccountForm.countryAbbrev === "CA";
          })
        },
        postalCode: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(10)
        }
      }
    };
  },
  computed: {
    formattedCountries() {
      return this.countries.map(country => ({
        text: country.name,
        value: country.abbrev,
        key: country.abbrev
      }));
    },
    regionOptions() {
      if (this.completeAccountForm.countryAbbrev === "US") {
        return usStates;
      } else if (this.completeAccountForm.countryAbbrev === "CA") {
        return canadianProvinces;
      }
      return [];
    },
    radioLabels() {
      const { firstName, lastName } = this.completeAccountForm;
      const lastInitial = lastName ? `${lastName.charAt(0).toUpperCase()}` : "";
      return {
        firstLast: firstName && lastInitial ? `${firstName} ${lastInitial}` : "First Name & Last Initial",
        fullName: firstName && lastName ? `${firstName} ${lastName}` : "Full Name"
      };
    }
  },
  mounted() {
    if (this.$auth.user && this.$auth.user.email_verified) {
      this.completeAccountForm.firstName = this.$auth.user.first_name;
      this.completeAccountForm.lastName = this.$auth.user.last_name;
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    async convertFile(file) {
      try {
        const base64Data = await convertFileToBase64String(file);
        this.completeAccountForm.avatarUrl = base64Data;
      } catch (error) {
        this.$root.$emit("universal-error-message", "Error converting file to Base64");
      }
    },
    async UploadUserAvatar() {
      const { avatarUrl } = this.completeAccountForm;
      const result = await UserApi.uploadUserAvatar(this.$auth.user.id, avatarUrl);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async completeRegistration() {
      const { firstName, lastName, isLastNameHidden, countryAbbrev, postalCode, city, region } = this.completeAccountForm;
      const result = await UserApi.completeRegistration(this.$auth.user.id, firstName, lastName, isLastNameHidden, countryAbbrev, postalCode, city, region);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onAccountCompleted() {
      this.$v.completeAccountForm.$touch();
      if (this.$v.completeAccountForm.$invalid) {
        this.$root.$emit("universal-error-message", "Please complete the form to finish your account registration.");
        return;
      }
      const completeRegistrationResult = await this.completeRegistration();
      if (!completeRegistrationResult.success) {
        return;
      }

      if (this.completeAccountForm.avatarUrl) {
        const uploadUserAvatarResult = await this.UploadUserAvatar();
        if (!uploadUserAvatarResult.success) {
          return;
        }
      }

      this.$emit("submit");
    }
  }
};
</script>
<template>
  <SuawForm>
    <template #form>
      <SuawHeading level="3" content="Complete Your Account" alignment="center" />
      <SuawInputGroup no-wrap>
        <SuawTextInput
          id="first-name-complete-account"
          v-model="completeAccountForm.firstName"
          type="text"
          placeholder="First Name"
          is-required
          label="First Name"
          :state="formFieldState($v, 'completeAccountForm', 'firstName')"
          :error-message="validateErrors($v.completeAccountForm.firstName, 'First name')"
          @blur="$v.completeAccountForm.firstName.$touch()"
        />
        <SuawTextInput
          id="last-name-complete-account"
          v-model="completeAccountForm.lastName"
          type="text"
          placeholder="Last Name"
          is-required
          label="Last Name"
          :state="formFieldState($v, 'completeAccountForm', 'lastName')"
          :error-message="validateErrors($v.completeAccountForm.lastName, 'Last name')"
          @blur="$v.completeAccountForm.lastName.$touch()"
        />
      </SuawInputGroup>
      <SuawInputGroup direction="column" group-gap="base">
        <SuawLabel label-text="Display Name" weight="bold" size="small" />
        <SuawRadio id="firstLast" v-model="completeAccountForm.isLastNameHidden" name="chooseName" :label="radioLabels.firstLast" :native-value="true" />
        <SuawRadio id="full" v-model="completeAccountForm.isLastNameHidden" name="chooseName" :label="radioLabels.fullName" :native-value="false" />
      </SuawInputGroup>
      <SuawSelect
        v-if="!loadingCountries"
        v-model="completeAccountForm.countryAbbrev"
        label="Country"
        placeholder="Select your country"
        :is-required="completeAccountForm.countryAbbrev === 'US' || completeAccountForm.countryAbbrev === 'CA'"
        :options="formattedCountries"
        :state="formFieldState($v, 'completeAccountForm', 'countryAbbrev')"
        :error-message="validateErrors($v.completeAccountForm.countryAbbrev, 'Country')"
        success-message=""
        @blur="$v.completeAccountForm.countryAbbrev.$touch()"
      />
      <SuawSelect
        v-if="completeAccountForm.countryAbbrev === 'US' || completeAccountForm.countryAbbrev === 'CA'"
        id="region"
        v-model="completeAccountForm.region"
        :options="regionOptions"
        is-required
        :state="formFieldState($v, 'completeAccountForm', 'region')"
        :error-message="validateErrors($v.completeAccountForm.region, `${completeAccountForm.countryAbbrev === 'US' ? 'State' : 'Province'}`)"
        :placeholder="completeAccountForm.countryAbbrev === 'US' ? 'Select your state' : 'Select your province'"
        :label="completeAccountForm.countryAbbrev === 'US' ? 'State' : 'Province'"
        success-message=""
        label-weight="bold"
      />
      <SuawTextInput
        id="city-complete-account"
        v-model="completeAccountForm.city"
        type="text"
        label="City"
        is-required
        :state="formFieldState($v, 'completeAccountForm', 'city')"
        :error-message="validateErrors($v.completeAccountForm.city, 'City')"
      />
      <SuawTextInput
        id="postal-code-complete-account"
        v-model="completeAccountForm.postalCode"
        type="text"
        is-required
        :label="completeAccountForm.countryAbbrev === 'US' ? 'Zip Code' : 'Postal code'"
        :state="formFieldState($v, 'completeAccountForm', 'postalCode')"
        :error-message="validateErrors($v.completeAccountForm.postalCode, 'Postal code')"
        @blur="$v.completeAccountForm.postalCode.$touch()"
      />
      <SuawInputGroup direction="column" group-gap="half">
        <SuawLabel weight="bold" size="small" label-text="Profile Picture (Optional)" />
        <SuawFileUpload upload-title="Upload Profile Picture" @file-selected="convertFile" />
      </SuawInputGroup>
      <SuawParagraph
        text="By clicking Finish Account, you accept the <a href='/resources/terms-of-service' target='_blank'>Terms of Service</a> and <a href='/resources/code-of-conduct' target='_blank'>Code of Conduct</a>."
      />
      <SuawButton size="large" type="primary" button-text="Finish Account" @click="onAccountCompleted" />
    </template>
  </SuawForm>
</template>
